/* SUIT */
@font-face {
  font-family: 'SUIT-Regular';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

:root {
  --CHA-Blue_primary: #f05a22;
  --CHA-Blue_primary_hover: #f85f27;
  --CHA-Blue_primary_active: #f5571d;
  --CHA-Blue_second: #58595b;
  /* --CHA-Blue_primary: #13426b; */
  /* --DDU-Main-Color: #f05a22; */
  --Palette-DarkGray-80: #333843;
  --Palette-DarkGray-50: #5c5a5d;

  --Palette-Gray-70: #6a6a6a;
  --Palette-Gray-10: #f4f4f4;
  --Palette-DarkGray-20: #e0e2e7;
  --Palette-Gray-50: #a4a4a4;
  --cha_yellow: #f5ce3e;
}
.color-primary {
  color: var(--CHA-Blue_primary) !important;
}
.color-second {
  color: var(--cha_yellow) !important;
}
.bg-primary {
  background-color: var(--CHA-Blue_primary) !important;
}
.bg-second {
  background-color: var(--CHA-Blue_second) !important;
}
.bg-cardZoomOption {
  background-color: #212b3610;
}
.bg-tooltip {
  background-color: var(--Palette-DarkGray-80) !important;
}
.border-primary {
  border-color: var(--CHA-Blue_primary) !important;
}

body {
  font-family: 'SUIT';
}
.color-primary {
  color: var(--CHA-Blue_primary) !important;
}
.color-second {
  color: var(--CHA-Blue_second) !important;
}
.bg-primary {
  background-color: var(--CHA-Blue_primary) !important;
}
.bg-second {
  background-color: var(--CHA-Blue_second) !important;
}
.border-primary {
  border-color: var(--CHA-Blue_primary) !important;
}

.--Palette-DarkGray-80 {
  color: var(--Palette-DarkGray-80) !important;
}
.--Palette-DarkGray-50 {
  color: var(--Palette-DarkGray-50) !important;
}
.bg-DarkGray-20 {
  background-color: var(--Palette-DarkGray-20);
}
.color-DarkGray-20 {
  color: var(--Palette-DarkGray-20);
}
.--Palette-DarkGray-60 {
  color: #667085 !important;
}
.--Palette-Gray-50 {
  color: #a4a4a4 !important;
}
.--Palette-DarkGray-80 {
  color: #333843 !important;
}
.--Palette-Gray-70 {
  color: #6a6a6a !important;
}
.--Palette-Gray-80 {
  color: #333843 !important;
}
.--cha_yellow {
  color: var(--cha_yellow, #f5ce3e);
}
.font-suit {
  font-family: 'SUIT-Regular';
}

.font-openSans {
  font-family: 'Open Sans', sans-serif;
}

.h1 {
  color: #5c5d5d;
  font-size: 25px;
}

.fz-32 {
  font-size: 2rem;
}
.fz-26 {
  font-size: 1.675rem;
}
.fz-24 {
  font-size: 1.5rem;
}
.fz-22 {
  font-size: 1.375rem;
}
.fz-20 {
  font-size: 1.25rem;
}
.fz-18 {
  font-size: 1.125rem;
}
.fz-16 {
  font-size: 1rem;
}
.fz-15 {
  font-size: 0.9375rem;
}
.fz-14 {
  font-size: 0.875rem;
}
.fz-12 {
  font-size: 0.75rem;
}
.lh-22 {
  line-height: 22px;
}
.lh-24 {
  line-height: 24px;
}
.lh-26 {
  line-height: 26px;
}

.h2 {
  color: #5c5d5d;
  font-size: 18px;
}
.h3-large {
  color: #5c5d5d;
  font-size: 18px;
}
.body {
  color: #5c5d5d;
  font-size: 16px;
}
.body-sub {
  color: #5c5d5d;
  font-size: 14px;
}
.tiny {
  color: #5c5d5d;
  font-size: 10px;
}

.text-gray1 {
  color: #333843;
}
.text-gray2 {
  color: #5c5d5d;
}
.text-blue1 {
  color: #0d6efd;
}
.Yellow-500 {
  color: #ffc228;
}
.Blue-500 {
  color: #1859ff;
}
.danger-redText {
  color: #e90f0f;
}
.Blue-600 {
  color: #1243bf;
}
.Blue_primary {
  background: #13426b;
}
.text-blue-primary {
  color: #13426b !important;
}
.btn-blue-primary {
  &:hover {
    background: #13426b !important;
    color: #fff !important;
  }
}

.CHA_LIGHT {
  background: #feeee9 !important;
}
.CHA_BLUE {
  background: #13426b !important;
}

/* openSans */
.noto-sans {
  font-family: 'Open Sans', sans-serif;
}

.fw-500 {
  font-weight: 500;
}

.popupBtn {
  /* border: 1px solid #dfe4ea; */
}
.color-inherit {
  color: inherit !important;
}
