body {
  font-family: 'SUIT-Regular';
}

.bg-red {
  background: red !important;
}
.bg-blue {
  background: blue !important;
}
.bg-gray {
  background: #c4c4c4 !important;
}

.popupContainer {
  max-width: 60vw;
}

.min-h-80 {
  max-height: 80vh;
  overflow-y: auto;
}

.position-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.p-20 {
  padding: 20px !important;
}
.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.p-30 {
  padding: 30px;
}
.m-20 {
  margin: 20px;
}
.mx-20 {
  margin-left: 20px;
  margin-right: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.ml-20 {
  margin-left: 20px;
}

.bg-gray10 {
  background: #f4f4f4;
}

.cursor-pointer {
  cursor: pointer;
}

.white-nowrap {
  white-space: nowrap;
}

.bg-none {
  background: none !important;
}
.bg-primary {
  background: #0d6efd;
}

.fz-19 {
  font-size: 19px;
}
/* ------------------------------------------------------------------ */
.tap-border {
  border-top-left-radius: 4px 4px;
  border-top-right-radius: 4px 4px;
  border-bottom: none !important;
}

.after-rightBorder {
  display: flex;
  align-items: center;
  &::after {
    content: '';
    margin-left: 20px;
    margin-right: 6px;
    display: block;
    width: 1px;
    height: 31px;
    background: #000;
  }
}

/* skeleton */
.box {
  max-width: 300px;
  width: 100%;
  padding: 0 15px;
}
.skeleton {
  /* padding: 15px; */
  width: 100%;
  background: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.skeleton .square {
  height: 26px;
  border-radius: 5px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-squares 2s infinite ease-out;
}
.skeleton .line {
  height: 26px;
  border-radius: 2px;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  background-size: 800px 100px;
  animation: wave-lines 2s infinite ease-out;
}

.skeleton .line:last-child {
  margin-bottom: 0;
}

.m10 {
  margin-bottom: 10px !important;
}

@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.w-full {
  width: 100% !important;
}

.d-flex {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.min-w-300 {
  min-width: 28dvw;
}
