:root {
  --main-font-color: #333843;
  --sub-font-color: #6a6a6a;
}

.backdrop {
  background: rgba(57, 57, 57, 0.5);
}

.bg-white {
  background: white;
}
